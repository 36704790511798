import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./footer.css";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <div className="footer-grid-container">
        <div className="footer-grid-item">
          <h5>About Us</h5>
          <p>
            We provide comprehensive end-to-end solutions in Data Acquisition
            (DAQ) systems, system integration, and sensor conditioning.{" "}
          </p>
        </div>
        <div className="footer-grid-item">
          <h5>Services</h5>
          <ul className="list-unstyled">
            <li>Software GUI</li>
            <li>LabView</li>
            <li>C/C++/Python/Matlab/Linux Software Deployment</li>
          </ul>
        </div>
        <div className="footer-grid-item">
          <h5>Products</h5>
          <ul className="list-unstyled">
            <li>Software Engineering</li>
            <li>Hardware Engineering</li>
            <li>Mechanical Engineering and 3D Printing Solutions</li>
          </ul>
        </div>
        <div className="footer-grid-item">
          <h5>Contact</h5>
          <p>evlabsindia@gmail.com</p>
          <p>
            <strong>Address:</strong> Float No 124, Savitari Nagar Near IIt
            DELHI 110017
          </p>
        </div>
        <div className="footer-grid-item">
          <h5>Follow Us</h5>
          <ul className="list-unstyled d-flex">
            <li className="me-3">
              <a href="#" className="text-white">
                <FaFacebookF />
              </a>
            </li>
            <li className="me-3">
              <a href="#" className="text-white">
                <FaTwitter />
              </a>
            </li>
            <li>
              <a href="#" className="text-white">
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
