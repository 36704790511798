import React from "react";
import { useParams } from "react-router-dom";
import "./productD.css";
import products from "./Products";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StickyNavbar from "../../components/Navbar";
import Footer from "../../components/Footer";

function ProductDetail() {
  const { id } = useParams();
  const product = products.find((product) => product.id === parseInt(id));
  if (!product) {
    return <div>Product not found!</div>;
  }

  const topImage = product.images[0];
  const otherImages = product.images.slice(1);

  return (
    <div className="product-detail">
      <StickyNavbar />
      <div className="product-top-image">
        <img src={topImage} alt={`${product.name} 1`} />
      </div>

      <Container className="container">
        <Row className="tum">
          <Col md={6}>
            <div className="product-images">
              {otherImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${product.name} ${index + 2}`}
                />
              ))}
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="product-description">
              <h2>{product.name}</h2>
              <p className="desc">{product.description}</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default ProductDetail;
