// src/products.js

import Software1 from "./../../images/soft1.jpg";
import Software2 from "./../../images/soft2.jpg";
import Hardware1 from "./../../images/hardware 1.jpg";
import Hardware2 from "./../../images/hardware2.jpg";
import Mach1 from "./../../images/m1.jpg";
import Mach2 from "./../../images/m2.jpg";

const products = [
  {
    id: 1,
    name: "Software Engineering",
    description:
      "evLabs India's product engineering team brings extensive expertise in system software development. From creating specifications and designing to implementation and independent testing, our team excels in delivering embedded engineering solutions. Our skills span microprocessors, real-time operating systems, low-level system software, and OS internals. We handle turnkey projects and offer discrete services for existing systems, addressing business challenges by reducing size and complexity while delivering next-generation solutions. We offer Modified COTS for changes such as different connectors, crystal frequencies, input/output voltage levels, minor FPGA modifications, and more.",
    images: [Software1, Software2],
  },
  {
    id: 2,
    name: "Hardware Engineering",
    description:
      "As a proven engineering services partner, evLabs India offers end-to-end product development solutions. From initial architecture to manufacturing support, our comprehensive hardware engineering services cover small form factor designs to complex PCB layouts, power-optimized to rugged designs for IoT, defense, industrial, oceanic, and automotive sectors. Our expertise includes customized FPGA solutions, integrating firmware and hardware engineering, high-speed bus interfaces, video processing, data acquisition, network processing, and machine learning. For fully custom designs, we collaborate closely with customers to define target specifications and deliver comprehensive in-house solutions, including schematics, PCB layout, firmware/software, prototyping, and production. Our post-development Product Lifecycle Management ensures ongoing support and redesigns as needed.",

    images: [Hardware1, Hardware2],
  },
  {
    id: 3,
    name: "Mechanical Engineering and 3D Printing Solutions",
    description:
      "Our expertise in mechanical design engineering enables customers to develop innovative and effective solutions. evLabs India offers services such as benchmarking, industrial design, detailed engineering design, reverse engineering, value engineering, thermal and structural analysis, detailing, and prototyping, all under one roof. Our advanced 3D printing solutions support rapid prototyping, enabling quick validation and refinement of designs to accelerate the product development cycle.",

    images: [Mach1, Mach2],
  },
];
export default products;
