import React, { Suspense, lazy } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Iot from "../images/home11.jpg";
import "./Home.css";
import StickyNavbar from "../components/Navbar";
import Footer from "../components/Footer";

const BusinessUnits = lazy(() => import("../components/businessUnit"));
const ExperiencePage = lazy(() => import("../components/Experties"));

function HomePage() {
  return (
    <div className="homepage">
      <StickyNavbar />
      <Container fluid>
        <Row>
          <Col xs={12} className="full-width-col">
            <img src={Iot} alt="Top" className="cover-image" />
          </Col>
        </Row>
      </Container>
      <Suspense fallback={<div>Loading...</div>}>
        <BusinessUnits />
        <ExperiencePage />
      </Suspense>
      <Footer />
    </div>
  );
}

export default HomePage;
