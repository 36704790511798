import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Footer from "../components/Footer"; // Import Footer component
import "./contact.css";
import StickyNavbar from "../components/Navbar";

const ContactUs = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log("Form submitted");
  };

  return (
    <>
      <StickyNavbar /> {/* Include StickyNavbar component */}
      <Container className="contact-container">
        <h1 className="contact-header text-center">Contact Us</h1>
        <Row className="mt-5 contact-row">
          <Col xs={12} md={6} className="mb-4">
            <div className="contact-section contact-form">
              <h2 className="contact-subheader">Get in Touch</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mt-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} required />
                </Form.Group>

                <Button type="submit" className="contact-button mt-3">
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="contact-section contact-info">
              <h2 className="contact-subheader">Contact Information</h2>
              <p>
                <strong>Address:</strong> Float No 124, Savitari Nagar Near IIt
                DELHI 110017
              </p>
              {/* <p>
                <strong>Phone:</strong> +91 79885 32147
              </p> */}
              <p>
                <strong>Email:</strong> evlabsindia@gmail.com
              </p>
              <h2 className="mt-4">Follow Us</h2>
              <p>
                <a href="#" className="text-decoration-none me-3">
                  Facebook
                </a>
                <a href="#" className="text-decoration-none me-3">
                  Twitter
                </a>
                <a href="#" className="text-decoration-none">
                  LinkedIn
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer /> {/* Include Footer component */}
    </>
  );
};

export default ContactUs;
