import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Iot from "../images/s4.jpg";
import Lab from "../images/labView.jpg";
import LabsetUp from "../images/s6.jpg";
import Soft from "../images/soft.jpg";
import sys from "../images/sys.jpg";
import "./business.css"; // Import the custom CSS file

const BusinessUnits = () => {
  return (
    <Container className="section">
      <h1 className="servies">Our Services</h1>
      <Row className="" style={{ minHeight: "100vh", marginBottom: "50px" }}>
        <Col md={6} className="mt-250">
          <img src={Iot} alt="Placeholder" className="img-fluid custom-image" />
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>Scientific Graphical Programming</h3>
            <p>
              We specialize in designing intuitive graphical user interfaces
              (GUIs) that enhance user interaction and experience. Our approach
              leverages the power of LabVIEW, a leading system design and
              development platform. LabVIEW is particularly well-suited for
              applications in test, measurement, and control systems, providing
              robust solutions for complex engineering challenges. Our services
              are tailored to meet the specific needs of our clients, ensuring
              that the GUIs we develop are not only functional but also
              user-friendly and efficient.
            </p>
          </div>
        </Col>

        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>Embedded Systems and Data Acquisition Systems</h3>
            <p>
              Our expertise in embedded systems and data acquisition systems
              allows us to deliver comprehensive solutions that meet rigorous
              industry standards. Using LabVIEW, we develop systems that are
              highly reliable and efficient, ensuring accurate data collection
              and processing. Whether it's for real-time monitoring or long-term
              data logging, our solutions are designed to integrate seamlessly
              with existing infrastructure, providing critical insights that
              drive informed decision-making and innovation.
            </p>
          </div>
        </Col>
        <Col md={6} className="mt-250">
          <img src={Lab} alt="Placeholder" className="img-fluid custom-image" />
        </Col>
        <Col md={6} className="mt-250">
          <img
            src={LabsetUp}
            alt="Placeholder"
            className="img-fluid custom-image"
          />
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>R&D and Laboratory Setup</h3>
            <p>
              We offer specialized services for setting up research and
              development (R&D) laboratories that cater to the unique needs of
              various projects. Our team works closely with clients to
              understand their specific requirements, ensuring that the
              laboratory environments we create are fully equipped to handle
              advanced research tasks. From selecting the right equipment to
              optimizing lab layout for efficiency, we provide end-to-end
              solutions that support cutting-edge innovation and discovery.
            </p>
          </div>
        </Col>

        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>C/C++/Python/MATLAB/Linux Software Deployment</h3>
            <p>
              Our software deployment services cover a wide range of programming
              languages and platforms, including C, C++, Python, MATLAB, and
              Linux. We specialize in creating and deploying software solutions
              that are both robust and scalable, ensuring that they meet the
              demands of various applications. Whether you're developing complex
              simulations, automating tasks, or implementing real-time systems,
              our team provides the expertise needed to ensure successful
              deployment and optimal performance across different environments.
            </p>
          </div>
        </Col>
        <Col md={6} className="mt-250">
          <img
            src={Soft}
            alt="Placeholder"
            className="img-fluid custom-image"
          />
        </Col>
        <Col md={6} className="mt-250">
          <img src={sys} alt="Placeholder" className="img-fluid custom-image" />
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>System Design Underwater Applications, Defense Projects</h3>
            <p>
              Our system design services are focused on creating secure,
              reliable solutions for specialized applications such as underwater
              systems and defense projects. We incorporate advanced sensors,
              real-time data processing capabilities, and secure communication
              protocols to ensure that these systems can effectively monitor and
              respond to various challenges. Our designs are tailored to meet
              the stringent requirements of mission-critical operations,
              providing peace of mind in even the most demanding environments.
            </p>
          </div>
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>Low-Power Hardware Design</h3>
            <p>
              In today's world, energy efficiency is paramount. Our low-power
              hardware design services focus on developing systems that minimize
              energy consumption without compromising performance. We utilize
              the latest technologies and design principles to create hardware
              solutions that are not only efficient but also highly reliable.
              These designs are ideal for applications where power availability
              is limited, such as remote sensing, IoT devices, and portable
              electronics.
            </p>
          </div>
        </Col>
        <Col md={6} className="mt-250">
          <img
            src={Soft}
            alt="Placeholder"
            className="img-fluid custom-image"
          />
        </Col>
        <Col md={6} className="mt-250">
          <img src={sys} alt="Placeholder" className="img-fluid custom-image" />
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>Software Integration</h3>
            <p>
              Software integration is essential for creating cohesive systems
              where different software applications work together seamlessly.
              Our services include integrating various software systems to
              enable effective communication, data sharing, and overall system
              efficiency. By reducing redundancy and minimizing errors,
              integration enhances scalability and interoperability, making it
              crucial for optimizing technology in complex environments like
              enterprises or IoT ecosystems. Our solutions ensure that your
              technology infrastructure is not only robust but also
              future-ready.
            </p>
          </div>
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>LoRaWAN</h3>
            <p>
              LoRaWAN is a key technology for enabling low-power, wide-area
              network (LPWAN) applications, especially in the context of the
              Internet of Things (IoT). Our services include developing and
              deploying LoRaWAN-based solutions that are designed to support
              long-range communication with minimal power consumption. These
              solutions are ideal for smart cities, industrial monitoring, and
              agricultural applications, where reliable, energy-efficient
              communication is essential for large-scale deployments.
            </p>
          </div>
        </Col>
        <Col md={6} className="mt-250">
          <img
            src={Soft}
            alt="Placeholder"
            className="img-fluid custom-image"
          />
        </Col>
        <Col md={6} className="mt-250">
          <img src={sys} alt="Placeholder" className="img-fluid custom-image" />
        </Col>
        <Col md={6} className="section mt-250">
          <div className="content">
            <h3>NB-IoT</h3>
            <p>
              Narrowband IoT (NB-IoT) is a low-power, wide-area network
              technology optimized for IoT devices. Our services include
              developing NB-IoT solutions that ensure long battery life, deep
              coverage, and reliable connectivity using existing LTE
              infrastructure. These solutions are ideal for smart meters, asset
              tracking, environmental monitoring, and other applications
              requiring low data rates. By supporting massive IoT deployments,
              NB-IoT enables scalable, cost-effective solutions for the growing
              demands of the IoT ecosystem.
            </p>
          </div>
        </Col>
      </Row>

      {/* <Row className="" style={{ minHeight: "100vh", marginTop: "50px" }}>
        <Col md={6} className="mt-250 section">
          <div className="content">
            <h1>Second Section Title</h1>
            <p>Content for the second section goes here.</p>
          </div>
        </Col>
        <Col md={6} className="mt-250">
          <img src={Iot} alt="Placeholder" className="img-fluid custom-image" />
        </Col>
      </Row> */}

      {/* Add more rows as needed */}
    </Container>
  );
};

export default BusinessUnits;
