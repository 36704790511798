import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StickyNavbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ServiceImage from "../../images/service.jpg";
import Service1 from "../../serviceImg/labView.jpg";
import Service2 from "../../serviceImg/s2.jpg";
import Service3 from "../../serviceImg/s6.jpg";
import Service4 from "../../serviceImg/soft.jpg";
import Service5 from "../../serviceImg/s5.jpg";
import Service6 from "../../serviceImg/service.jpg";
import Service7 from "../../serviceImg/s7.jpg";
import Service8 from "../../serviceImg/home3.jpg";
import "./service.css"; // Import the CSS file

const Services = () => (
  <div className="services-detail">
    <StickyNavbar />
    <div className="services-top-image">
      <img src={ServiceImage} alt="Services" />
      <h1 className="overlay-text">Services</h1>
    </div>

    <Container className="container">
      <Row className="tum">
        <ServiceCard
          image={Service5}
          name="Software GUI"
          description="Designing graphical user interfaces (GUIs) for software applications to enhance user interaction and experience."
        />
        <ServiceCard
          image={Service1}
          name="LabView"
          description="Utilizing LabView, a system design and development platform, commonly used for test, measurement, and control systems."
        />
        <ServiceCard
          image={Service3}
          name="Laboratory Setup for Custom Projects"
          description="Providing services to set up specialized laboratory environments tailored to specific project requirements."
        />
        <ServiceCard
          image={Service4}
          name="C/C++/Python/Matlab/Linux Software Deployment"
          description="Developing and deploying software solutions using C, C++, Python, MATLAB, and Linux platforms."
        />
        <ServiceCard
          image={Service5}
          name="System Integration"
          description="Integrating various hardware and software components to create cohesive systems that fulfill specific functions or tasks."
        />
        <ServiceCard
          image={Service6}
          name="EMI/RFI Enclosure Solutions"
          description="Designing and implementing solutions to mitigate electromagnetic interference (EMI) and radio-frequency interference (RFI) in electronic systems."
        />
        <ServiceCard
          image={Service7}
          name="PCB Fabrication and Assembly"
          description="Offering services for the design, fabrication, and assembly of printed circuit boards (PCBs), essential in electronic devices."
        />
        <ServiceCard
          image={Service8}
          name="Precision Sensors for PoCs"
          description="Developing and integrating high-precision sensors into proof-of-concept (PoC) projects for experimental or research purposes."
        />
        <ServiceCard
          image={Service2}
          name="Mechanical Prototyping"
          description="Creating physical prototypes of mechanical components or systems to validate designs or test functionality before full-scale production."
        />
      </Row>
    </Container>
    <Footer />
  </div>
);

const ServiceCard = ({ image, name, description }) => (
  <Col lg={4} md={6} sm={12} className="mb-4">
    <div className="card h-100">
      <img src={image} alt={name} className="card-img-top" />
      <div className="card-body">
        <h3 className="card-title">{name}</h3>
        <p className="card-text">{description}</p>
      </div>
    </div>
  </Col>
);

export default Services;
